import React from "react"
import Link from "components/_ui/Link/Link"
import Intro from "components/Intro/Intro"
import Layout from "components/Layout/Layout"
import MaxWidth from "components/_ui/MaxWidth/MaxWidth";
import BookshelfContainer from "components/Bookshelf/BookshelfContainer";
// import Project from "components/Project/Project";
// import projects from "components/Project/projects.js";
import SEO from "components/SEO/SEO"

const Home = () => (
    <Layout showNavigation showSocialCol showFooter>
        <SEO />
        <Intro />
        <MaxWidth className="Home__recent">
            <div>
                <h2>
                    Experience
                </h2>
                <Link 
                    doOpenInNewTab
                    to="https://www.dropbox.com/s/9i90j0i26q3pte9/ahadsheriff_resume.pdf?dl=0"
                    className="Home__recent__item Home__recent__item--post">
                    <div>
                        <p>
                            Eng @ Zoom
                        </p>
                        <p>
                            Ex @ VMware
                        </p>
                        <p>
                            B.S. @ RIT (2019)
                        </p>
                    </div>

                </Link>
            </div>
            <div>
                <h2>
                    Recent Post
                </h2>
                {/* <Link to="/blog/set-up-local-website" */}
                <Link 
                    doOpenInNewTab
                    to="https://medium.com/swlh/run-x86-terminal-apps-like-homebrew-on-your-new-m1-mac-73bdc9b0f343"
                    className="Home__recent__item Home__recent__item--post">
                    <div>
                        <h3>
                            Run x86 Terminal Apps (Like Homebrew) on Your New M1 Mac
                        </h3>
                        <p>
                            (Hint: You do it with Rosetta 2)
                        </p>
                    </div>
                </Link>
            </div>
        </MaxWidth>
        <MaxWidth id="work" className="Home__projects">
            {/* <h1>
                Projects
            </h1>
            {Object.keys(projects).map((key) =>
                <Project
                    demo={projects[key].demo}
                    description={projects[key].description}
                    github={projects[key].github}
                    hasImageBorder={projects[key].hasImageBorder}
                    images={projects[key].img_src}
                    imgType={projects[key].imgType}
                    inspiration={projects[key].inspiration}
                    projectType={projects[key].projectType}
                    tech={projects[key].tech}
                    title={projects[key].title}
                    readMore={projects[key].readMore}
                    key={key}
                />
            )} */}
            <BookshelfContainer />
        </MaxWidth>
    </Layout>
);

export default Home;
