import React from "react";
//Help — book API, where r u? GoogleBooks? wip
//Summaries and images from goodreads.com

const books = [
    {
        title: "Crashdeck",
        author: "Fix your broken code faster.",
        finished: false,
        inProgress: true,
        "tags": [
            "web",
            "saas",
        ],
        genre: "saas",
        audiobook: false,
        isFiction: false,
        goodreadsUrl: "https://crashdeck.io",
        coverImg: "",
        summary: <div>
            <p>
                Crashdeck makes it easy to track errors and get real-time insights to help you fix your broken code at lightning speed. In closed beta.
            </p>
        </div>
    },
    {
        title: "Anything.fyi",
        author: "Create and share collections of things.",
        finished: false,
        inProgress: true,
        "tags": [
            "web",
        ],
        genre: "web",
        isFiction: false,
        goodreadsUrl: "https://www.anything.fyi",
        coverImg: "",
        summary: <div>
            <p>
                The internet is huge, curate the important things to share with others. In closed beta.
            </p>
        </div>
    },
    {
        title: "Phaser",
        author: "The fastest notes app in the galaxy.",
        finished: false,
        inProgress: true,
        "tags": [
            "mobile",
        ],
        genre: "mobile",
        isFiction: false,
        goodreadsUrl: "www.phasernote.com",
        coverImg: "",
        summary: <div>
            <p>
                Phaser is an app for quick todos, time sensitive notes and reminders.
            </p>
        </div>
    },
    {
        title: "12for12",
        author: "Technology to power charitable giving.",
        finished: true,
        audiobook: false,
        inProgress: false,
        "tags": [
            "non-profit",
            "web",
        ],
        genre: "non-profit",
        isFiction: false,
        goodreadsUrl: "https://kitsforpeace.com",
        coverImg: "",
        summary: <div>
            <p>
                12for12 is a registered 501c3 non-profit organization that started off as a service that allowed individuals to round up their spare change to be donated to a new curated charity every month.
            </p>
            <p>
                We have since decided to pause our round up service and merge with Kits for Peace (EIN: 833737538) to create and distribute kits with food cards, hygiene products, and warm weather essentials to help those experiencing homelessness.
            </p>
        </div>
    },
    {
        title: "Robot Ahad",
        author: "A Twitter bot that tweets like me.",
        finished: true,
        inProgress: false,
        "tags": [
            "web",
            "ai",
        ],
        genre: "ai",
        isFiction: false,
        goodreadsUrl: "https://twitter.com/ahadsheriffbot",
        coverImg: "",
        summary: <div>
            <p>
                A twitter bot that uses a recurrent neural network and Markov chain to generate tweets like @ahadsheriff
            </p>
        </div>
    },
    {
        title: "Respond First",
        author: "A mobile app to report narcotic overdoses.",
        finished: true,
        inProgress: false,
        "tags": [
            "mobile",
        ],
        genre: "mobile",
        isFiction: false,
        goodreadsUrl: "https://devpost.com/software/respond-first-lzde25",
        coverImg: "",
        summary: <div>
            <p>
                Helps to increase survival rate of overdoses by facilitating the communication between someone reporting the incident and people nearby who are either CPR certified/ can administer Narcan.
            </p>
        </div>
    },
    {
        title: "Roast",
        author: "Roasts as a Service.",
        finished: true,
        inProgress: false,
        "tags": [
            "mobile",
        ],
        isFiction: false,
        genre: "mobile",
        goodreadsUrl: "https://www.theroastapp.com",
        coverImg: "",
        summary: <div>
            <p>
                Roast started off as a third-party keyboard app that let users roast their friends at the tap of a button and amassed nearly 1 million App Store downloads. The app has since evolved into a community for sharing jokes and memes online, and we plan on opening up our roasts API to the public starting later in 2021.
            </p>
        </div>
    },
    {
        title: "PermissionHunter",
        author: "Android app risk scoring service.",
        finished: true,
        audiobook: false,
        inProgress: false,
        "tags": [
            "security",
        ],
        isFiction: false,
        genre: "security",
        goodreadsUrl: "https://github.com/ahadsheriff/PermissionHunter",
        coverImg: "",
        summary: <div>
            <p>
                This tool checks all of the permissions in an app against Androids list of Normal, Dangerous, and Signed permissions and then lists them as well as custom and other unclassified Android permissions, and recieves an overall risk score. This tool helps researchers and developers discover which apps users should be wary of.
            </p>
        </div>
    },
    {
        title: "Shelly",
        author: "A simple offensive security tool.",
        finished: true,
        audiobook: false,
        inProgress: false,
        "tags": [
            "security",
        ],
        isFiction: false,
        genre: "security",
        goodreadsUrl: "https://github.com/ahadsheriff/Shelly",
        coverImg: "",
        summary: <div>
            <p>
                A reverse shell program that allows you to remotely control another machine.
            </p>
        </div>
    },
    {
        title: "Security Suite",
        author: "A collection of audit and pentest scripts.",
        finished: true,
        audiobook: false,
        inProgress: false,
        "tags": [
            "security",
        ],
        isFiction: false,
        genre: "security",
        goodreadsUrl: "https://github.com/ahadsheriff/security_suite",
        coverImg: "",
        summary: <div>
            <p>
                A collection of audit and penetration testing scripts that can be used for cyber security purposes. 
                
                Audit tools include: DNS enumeration tool, ICMP based OS detection tool, ping sweep tool, port scanning tool, and brute force SSH login scanning tool. 
                
                Pentest tools include: A program that crawls a website and recursively checks links to scrape all relevant email addresses, program that uses "the Harvester" tool to gather emails, subdomains, hosts, employee names, open ports and banners from different public sources, tool to scrape a web page for email addresses, crawls a website and recursively checks links to map all internal and external links, and a script that scans ports on a target machine using netcat.
            </p>
        </div>
    },
    {
        title: "Website Mapper",
        author: "A tool to recursively scrape websites.",
        finished: true,
        audiobook: false,
        inProgress: false,
        "tags": [
            "security",
        ],
        isFiction: false,
        genre: "security",
        goodreadsUrl: "https://github.com/ahadsheriff/map-website",
        coverImg: "",
        summary: <div>
            <p>
                A Python CLI tool that recursively scrapes a website and generates a map of all internal and external links it can find as well as other metadata including images.
            </p>
        </div>
    },
    {
        title: "Packet Sniffer",
        author: "Sniff network packets on your command line.",
        finished: true,
        audiobook: false,
        inProgress: false,
        "tags": [
            "security",
        ],
        isFiction: false,
        genre: "security",
        goodreadsUrl: "https://github.com/ahadsheriff/PacketSniffer",
        coverImg: "",
        summary: <div>
        </div>
    },
    {
        title: "Unigram Project",
        author: "Compute data statistics for words in books.",
        finished: true,
        audiobook: false,
        inProgress: false,
        "tags": [
            "code",
        ],
        isFiction: false,
        genre: "code",
        goodreadsUrl: "https://github.com/ahadsheriff/UnigramProject",
        coverImg: "",
        summary: <div>
            <p>
                Used Python to: Compute the number of times each letter of the alphabet occurred between a range of years entered by a user, Compute the number of words printed in a specific year entered by a user, Compute the highest trending words and the lowest trending words printed between a range of years entered by a user, Computing and graphing word length distributions.
            </p>
        </div>
    },
];

export default books;