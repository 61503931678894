import React from "react"
import Link from "components/_ui/Link/Link";
import MaxWidth from "components/_ui/MaxWidth/MaxWidth";

import "./Intro.scss";

const Intro = () => {
    return (
        <MaxWidth
            size="m"
            className="Intro"
        >
            {/* <h2 className="Intro__greeting">
                You found me <span className="emoji" role="img" aria-label="Emoji mage"></span>
            </h2> */}

            <h1 className="Intro__header">
                🧙‍♂️ Ahad Sheriff.
                <br/>
            </h1>
            <h3 className="Intro__subheader">
            You met me at a very strange time in my life...
            </h3>
            <Link
                doOpenInNewTab
                isButton
                className="Intro__button"
                buttonProps={{size: "xl", color: "white-blue-stroke"}}
                to="https://calendly.com/ahadsheriff"
            >
                Let's talk
            </Link>
        </MaxWidth>
    );
};

export default Intro;